<template>
  <div class="form-elements">
    <div class="row">
      <div class="flex xs12">
        <va-card title="Account settings">
          <div class="row">
            <div class="flex md4 sm6 xs12">
              <h5>Change password</h5>
              <br />
              <va-input
                v-model="oldPassword"
                type="password"
                placeholder="Old password"
                required
              />
              <va-input
                v-model="newPassword"
                type="password"
                placeholder="New password"
                required
              />
              <va-input
                v-model="confirmPassword"
                type="password"
                placeholder="Confirm new password"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="flex md4 sm6 xs12">
              <va-button @click="updatePassword">Save</va-button>
            </div>
          </div>
        </va-card>
      </div>
    </div>
    <va-modal
      :value="!!error"
      size="small"
      title="Password change error"
      @cancel="clear"
      @ok="clear"
    >
      {{ error }}
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { changeSelfPassword } from '../../../apollo/api/admin-users';
export default {
  name: 'user-settings',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      error: null,
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    clear() {
      this.oldPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.error = null;
    },
    async updatePassword() {
      this.error = null;

      if (this.newPassword.length < 5 || this.oldPassword < 5) {
        this.error = 'Invalid password(s)';
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.error = "Your passwords don't match";
        return;
      }

      try {
        await changeSelfPassword(
          this.user.id,
          this.oldPassword,
          this.newPassword,
        );

        this.showToast('Account updated successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        this.clear();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
